import { defineStore } from 'pinia';
import type {
  IUserLimits,
  IBrandLimits,
  IRestrictionPermissions,
  IWagerProgressType,
  IRestrictionMediator,
} from '~/interfaces/dto/player/response';
import type { IRgStore } from '~/interfaces/stores/rg.store.interface';
import { rgKeyLookup, restrictionMappingKeys } from '~/assets/data/rgMapping';

const sessionRm: Partial<IRestrictionMediator> = rgKeyLookup.get(
  restrictionMappingKeys.sessionLimit,
);
const daily: Partial<IRestrictionMediator> = rgKeyLookup.get(
  restrictionMappingKeys.dailyWagerLimit,
);
const weekly: Partial<IRestrictionMediator> = rgKeyLookup.get(
  restrictionMappingKeys.weeklyWagerLimit,
);
const monthly: Partial<IRestrictionMediator> = rgKeyLookup.get(
  restrictionMappingKeys.monthlyWagerLimit,
);
const coolingOff: Partial<IRestrictionMediator> = rgKeyLookup.get(
  restrictionMappingKeys.coolingOff,
);
const selfExclusionGambling: Partial<IRestrictionMediator> = rgKeyLookup.get(
  restrictionMappingKeys.selfExclusionGamblingProblem,
);
const selfExclusionFinancial: Partial<IRestrictionMediator> = rgKeyLookup.get(
  restrictionMappingKeys.selfExclusionFinancialDifficulties,
);
const selfExclusionBreak: Partial<IRestrictionMediator> = rgKeyLookup.get(
  restrictionMappingKeys.selfExclusionGamblingBreak,
);
export const useRgStore = defineStore('rg', {
  state: (): IRgStore => {
    return {
      userLimits: null,
      brandLimits: null,
      brandLocks: null,
      userLocks: null,
      exclusions: null,
      currentSessionLimit: null,
      currentTimer: null,
      currentWagerDaily: null,
      currentWagerWeekly: null,
      currentWagerMonthly: null,
      currentCoolOffThresholdType: null,
      currentCoolOffThreshold: null,
      exclusionReason: null,
      timerSet: false,
      restrictionPermissions: {
        allowedPayout: true,
        allowedWithdrawal: true,
        allowedWager: true,
      },
      accountLocked: false,
      allowPayout: true,
      allowWithdrawal: true,
      allowWager: true,
      fullRestriction: false,
      wagerDurations: [],
    };
  },
  actions: {
    setBrandLimits(restrictionArr: IBrandLimits[]): void {
      this.brandLimits = [restrictionArr].reduce(() => this.brandLimits);
    },
    setUserLimits(restrictionArr: IUserLimits[]): void {
      restrictionArr.forEach((restriction) => {
        switch (restriction?.restrictionType?.mappingCode) {
          case sessionRm.mappingCode:
            this.currentSessionLimit = restriction.threshold;
            this.currentTimer = this.currentSessionLimit * 60;
            break;
          case daily.mappingCode:
            this.currentWagerDaily = restriction.threshold;
            break;
          case weekly.mappingCode:
            this.currentWagerWeekly = restriction.threshold;
            break;
          case monthly.mappingCode:
            this.currentWagerMonthly = restriction.threshold;
            break;
          case coolingOff.mappingCode:
            this.currentCoolOffThresholdType = restriction.thresholdType;
            this.currentCoolOffThreshold = restriction.threshold;
            this.allowWager =
              restriction.restrictionType.restrictionPermissions.allowedWager;
          default:
            break;
        }
      });
      this.userLimits = [restrictionArr].reduce(() => this.userLimits);
    },
    setUpdateTimer(time: number): void {
      this.currentTimer = time;
    },
    restoreSessionStore(): void {
      this.currentSessionLimit = null;
      this.currentTimer = null;
      this.timerSet = false;
    },
    setTimerSet(): void {
      this.timerSet = !this.timerSet;
    },
    setExclusions(exclusionsArr: String[]): void {
      this.exclusions = exclusionsArr;
    },
    setBrandLocks(restrictionArr: IBrandLimits[]): void {
      this.brandLocks = [restrictionArr].reduce(() => this.brandLocks);
    },
    setUserLocks(restrictionArr: IUserLimits[]): void {
      restrictionArr.forEach((restriction) => {
        switch (restriction?.restrictionType?.mappingCode) {
          case selfExclusionGambling.mappingCode:
            this.exclusionReason = 'I Have A Gambling Problem.';
            this.allowWager = false;
            break;
          case selfExclusionFinancial.mappingCode:
            this.exclusionReason = 'I Have Financial Difficulties.';
            this.allowWager = false;
            break;
          case selfExclusionBreak.mappingCode:
            this.exclusionReason = 'I Want To Take A Break From Gambling.';
            this.allowWager = false;
            break;
          default:
            break;
        }
      });
      this.userLocks = [restrictionArr].reduce(() => this.userLocks);
    },
    setRestrictionPermissions(permissions: IRestrictionPermissions): void {
      this.restrictionPermissions = permissions;
    },
    setAccountLockStatus(locked: boolean): void {
      this.accountLocked = locked;
    },
    setAllowPayout(flag: boolean): void {
      this.allowPayout = flag;
    },
    setAllowWithdrawal(flag: boolean): void {
      this.allowWithdrawal = flag;
    },
    setWagerDuration(durations: IWagerProgressType[]): void {
      this.wagerDurations = durations;
    },
  },
  getters: {
    getUserLocks(state): IUserLimits[] {
      return state.userLocks;
    },
    getUserLimits(state): IUserLimits[] {
      return state.userLimits;
    },
    getBrandLocks(state): IBrandLimits[] {
      return state.brandLocks;
    },
    getBrandLimits(state): IBrandLimits[] {
      return state.brandLimits;
    },
    getCurrentSessionLimit(state): number {
      return state.currentSessionLimit;
    },
    getTimerSet(state): boolean {
      return state.timerSet;
    },
    getCurrentWagerDaily(state): number {
      return state.currentWagerDaily;
    },
    getCurrentWagerWeekly(state): number {
      return state.currentWagerWeekly;
    },
    getCurrentWagerMonthly(state): number {
      return state.currentWagerMonthly;
    },
    getExclusions(state): String[] {
      return state.exclusions;
    },
    getRestrictionPermissions(state): IRestrictionPermissions {
      return state.restrictionPermissions;
    },
    getAccountLockStatus(state): boolean {
      return state.accountLocked;
    },
    getAllowPayout(state): boolean {
      return state.allowPayout;
    },
    getAllowWithdrawal(state): boolean {
      return state.allowWithdrawal;
    },
    getWagerDuration(state): IWagerProgressType[] {
      return state.wagerDurations;
    },
  },
});
