import { BaseService } from '~/services/base.service';
import { useGameStore } from '~/stores/games.store';
import { useSiteStore } from '~/stores/site.store';
import { useStorageService } from './useStorageService';
import type { FetchContext, FetchResponse } from 'ofetch';
import type {
  IFetchGameRequest,
  IGameCategoriesRequest,
  IGameFavouritesRequest,
  IGameProvidersRequest,
  IGameRatingRequest,
  IGetGameRating,
  IPlaytimeRequest,
  ISearchRequest,
} from '~/interfaces/dto/games/request';
import type {
  IFavouriteResponse,
  IGameCategories,
  IGameCategoriesResponse,
  IGameCategoriesV4,
  IGameCategoriesV4Data,
  IGameCategory,
  IGameCategoryResponse,
  ISearchResponse,
} from '~/interfaces/dto/games/response';
import dayjs from 'dayjs';

export const useGameService = () => {
  const appConfig = useRuntimeConfig();
  const $storageService = useStorageService();
  const base = new BaseService(appConfig.public.base);
  const baseC = new BaseService(appConfig.public.baseC);

  //stores
  const gameStore = useGameStore();
  const siteStore = useSiteStore();

  const route = useRoute();
  const retryAuthenticatedRequest = useRetryAuthenticatedRequest;

  const fetchBuildVersion = async ({
    regionCode = siteStore.getRegionCode,
  }: Partial<IGameCategoriesRequest>) => {
    const params = {
      regionCode,
      environment: appConfig.public.environment || 'development',
    };
    const requestUrl: string = baseC.requestUrl({
      path: `api/v3/Gaming/BuildVersion`,
    });

    return await $fetch(requestUrl, {
      query: params,
      async onResponse(context) {
        const { setCache, getCache } = await useCache<{ buildVersion: string }>(
          `buildVersion`,
        );

        if (!!getCache) {
          if (getCache?.buildVersion !== context.response._data?.buildVersion)
            await $storageService.invalidateBuildVersion();
        } else {
          await $storageService.invalidateBuildVersion();
        }
        gameStore.setBuildInformation(context.response._data);
        await setCache(context.response._data);
      },
    }).catch((e) => console.error(e));
  };

  const fetchGamesByProvider = async ({
    regionCode = siteStore.getRegionCode,
    vertical = String(route.params.contentType),
    channel = 'WebDesktop',
    count = 50,
    languageCode = 'en',
    currency = siteStore.getCurrencyCode,
    provider,
    skip = 0,
    limit = 50,
  }: IGameProvidersRequest) => {
    const params = {
      channel,
      count,
      languageCode,
      currency,
      provider,
      skip,
      limit,
      regionCode,
      vertical,
      environment: appConfig.public.environment || 'development',
    };
    const requestUrl: string = baseC.requestUrl({
      path: `api/v3/Gaming/Provider/Games`,
    });

    const providerKey = provider.toLowerCase().replace(/ /s, '-');
    const { setCache, getCache } = await useCache(
      `provider:${vertical}-${providerKey}-${skip}-${limit}`,
    );

    if (!!getCache) return getCache;

    return await $fetch<IGameCategoriesResponse>(requestUrl, {
      query: params,
      headers: {
        'X-Region-Code': regionCode,
        'X-Vertical': vertical,
        'X-Environment': appConfig.public.environment || 'development',
      },
      async onResponse(context) {
        const transformedData = context.response._data?.data?.map((game) => {
          return { ...game, provider };
        });
        const returnObject = {
          ...context.response._data,
          data: transformedData,
        };
        context.response._data = returnObject;
        await setCache(returnObject);
      },
    });
  };

  const fetchCategory = async ({
    regionCode = siteStore.getRegionCode,
    vertical,
    channel = 'WebDesktop',
    count = 50,
    languageCode = 'en',
    currency = siteStore.getCurrencyCode,
    category,
    skip = 0,
    limit,
  }: IGameCategoriesRequest) => {
    const params = {
      channel,
      count,
      languageCode,
      currency,
      category,
      skip,
      limit,
      regionCode,
      vertical,
      environment: appConfig.public.environment || 'development',
    };
    if (!vertical) return;
    const requestUrl: string = baseC.requestUrl({
      path: `api/v3/Gaming/${channel}/${category}`,
    });
    const verticalKey = vertical.toLowerCase().replace(/ /s, '-');
    const categoryKey = category.toLowerCase().replace(/ /s, '-');

    const { setCache, getCache } = await useCache(
      `category:${verticalKey}-${categoryKey}-${skip}-${limit}`,
    );

    if (!!getCache) return getCache;

    return await $fetch<IGameCategoriesResponse>(requestUrl, {
      query: params,
      headers: {
        'X-Region-Code': regionCode,
        'X-Vertical': vertical,
        'X-Environment': appConfig.public.environment || 'development',
      },
      async onResponse(context) {
        const transformedData = context.response._data?.data?.map((game) => {
          return { ...game, category };
        });
        context.response._data = {
          ...context.response._data,
          data: transformedData,
        };
        await setCache({
          ...context.response._data,
          data: transformedData,
        });
      },
    });
  };

  const fetchPersonalisedGames = async (
    {
      region,
      vertical,
      channel,
      count,
      gameCount,
      languageCode,
      currency,
    }: IGameCategoriesRequest = {
      region: siteStore.getRegionCode,
      vertical: 'Spin Games',
      channel: 'WebDesktop',
      count: 10,
      gameCount: 10,
      languageCode: 'en-US',
      currency: 'USD',
    },
  ) => {
    const params = {
      vertical,
      languageCode,
      channel,
      currency,
      region,
      limit: gameCount || count,
      environment: appConfig.public.environment || 'development',
    };
    const requestUrl: string = base.requestUrl({
      path: 'api/v3/Gaming/Game/Personalised',
    });
    return await retryAuthenticatedRequest<{
      favourites: IGameCategoriesV4;
      recommended: IGameCategoriesV4;
    }>(requestUrl, {
      query: params,
      headers: {
        'X-Environment': appConfig.public.environment || 'development',
      },
      async onResponse(context) {
        const { favourites, recommended } = context.response._data;
        const transformedData = favourites?.map((game) => {
          return { ...game, categories: { favourites: 1, ...game.categories } };
        });

        gameStore.setFavorites(transformedData);
        gameStore.setRecommended(recommended);
      },
    });
  };

  //v4 api
  const fetchCategories = async ({
    regionCode = siteStore.getRegionCode,
    vertical,
    channel,
    count,
    gameCount,
    languageCode,
    currency,
  }: IGameCategoriesRequest) => {
    const params = {
      channel,
      count,
      languageCode,
      currency,
      gameCount,
      environment: appConfig.public.environment,
      vertical,
      regionCode,
    };
    const requestUrl: string = baseC.requestUrl({
      path: 'api/v4/Gaming/Game/Categories',
    });
    if (!vertical)
      return {
        categories: [],
        providers: [],
        themes: [],
      };
    const verticalKey = vertical.toLowerCase().replace(/ /s, '-');
    const { setCache, getCache } = await useCache<IGameCategoriesV4Data>(
      `vertical:${verticalKey}`,
    );

    if (!!getCache) return getCache;

    return await $fetch<IGameCategoriesV4Data>(requestUrl, {
      query: params,
      headers: {
        'X-Region-Code': regionCode,
        'X-Vertical': vertical,
        'X-Environment': appConfig.public.environment || 'development',
      },
      async onResponse(
        context: FetchContext & { response: FetchResponse<ResponseType> },
      ): Promise<void> {
        const data = context.response._data;
        const isLaunchGame =
          data?.categories?.length === 1 &&
          data.categories[0]?.games.length === 1;
        const gameToLaunch = isLaunchGame
          ? {
              gameToLaunch: { game: data.categories[0]?.games[0] },
            }
          : {};
        const isSingleCategory = data.categories.length === 1;
        const category = isSingleCategory
          ? { category: data.categories[0]?.name }
          : {};
        const transformedData = {
          ...data,
          isLaunchGame,
          isSingleCategory,
          ...gameToLaunch,
          ...category,
        };
        context.response._data = transformedData;
        await setCache(transformedData);

        //Store array of game IDs
        setGames(data?.categories);
      },
    });
  };

  //
  const fetchGame = async ({
    gameId,
    regionCode = siteStore.getRegionCode,
    vertical,
    channel,
    count,
    languageCode,
    currency,
  }: IFetchGameRequest) => {
    const params = {
      channel,
      count,
      languageCode,
      currency,
      environment: appConfig.public.environment || 'development',
    };
    const requestUrl: string = baseC.requestUrl({
      path: `api/v1/Gaming/Game/${+gameId}`,
    });

    const { setCache, getCache } = await useCache(`game:${+gameId}`);

    if (!!getCache) {
      gameStore.setCurrentGame(getCache);
      return getCache;
    }
    return await $fetch<IGameCategoryResponse>(requestUrl, {
      query: params,
      headers: {
        'X-Region-Code': regionCode,
        'X-Vertical': vertical,
        'X-Environment': appConfig.public.environment || 'development',
      },
      async onResponse(
        context: FetchContext & {
          response: FetchResponse<IGameCategoriesV4>;
        },
      ): Promise<void> {
        gameStore.setCurrentGame(context.response._data);
        await setCache(context.response._data);
      },
    });
  };

  const fetchGameByAlias = async ({
    alias,
    regionCode = siteStore.getRegionCode,
    vertical = String(route.params.contentType),
    channel,
    count,
    languageCode,
    currency,
  }: IFetchGameRequest) => {
    const params = {
      channel,
      count,
      languageCode,
      currency,
      environment: appConfig.public.environment || 'development',
    };
    const requestUrl: string = baseC.requestUrl({
      path: `api/v1/Gaming/Game/${alias}/alias`,
    });

    const { setCache, getCache } = await useCache(`game:${alias}`);

    if (!!getCache) {
      gameStore.setCurrentGame(getCache);
      return getCache;
    }
    return await $fetch(requestUrl, {
      query: params,
      headers: {
        'X-Region-Code': regionCode,
        'X-Vertical': vertical,
        'X-Environment': appConfig.public.environment || 'development',
      },
      async onResponse(
        context: FetchContext & {
          response: FetchResponse<IGameCategoriesV4>;
        },
      ): Promise<void> {
        gameStore.setCurrentGame(context.response._data);
        await setCache(context.response._data);
      },
    });
  };

  //v2 global search
  const fetchSearchedGames = async ({
    regionCode = siteStore.getRegionCode,
    search,
    languageCode,
    channel,
    skip,
    limit,
    currency,
  }: ISearchRequest) => {
    const params = {
      search,
      languageCode,
      channel,
      skip,
      limit,
      currency,
      regionCode,
      environment: appConfig.public.environment || 'development',
    };
    const requestUrl: string = baseC.requestUrl({
      path: 'api/v4/Gaming/Search',
    });
    return await $fetch<ISearchResponse>(requestUrl, {
      query: params,
      headers: {
        'X-Region-Code': regionCode,
        'X-Environment': appConfig.public.environment || 'development',
      },
      onResponse({ response }) {
        const verticals = response._data.verticals.filter((vertical) => {
          return vertical.vertical !== 'Certification';
        });
        Object.assign(response._data, { verticals });

        return response._data;
      },
    });
  };

  const fetchGameFavourites = async ({
    channel,
    languageCode,
    vertical,
    currency,
  }: IGameFavouritesRequest) => {
    const params = {
      channel,
      languageCode,
      currency,
      environment: appConfig.public.environment || 'development',
    };
    const requestUrl: string = base.requestUrl({
      path: 'api/v1/Gaming/Game/Favourite',
    });

    return await retryAuthenticatedRequest<IFavouriteResponse[]>(requestUrl, {
      query: params,
      headers: {
        'X-Vertical': vertical,
        'X-Environment': appConfig.public.environment || 'development',
      },
      async onResponse(context) {
        const transformedData = context.response._data?.map((game) => {
          return { ...game, categories: { favorites: 1, ...game.categories } };
        });

        context.response._data = transformedData;

        gameStore.setFavorites(transformedData);
      },
    });
  };

  const addGameFavourites = async ({
    channel,
    languageCode,
    vertical,
    currency,
    game,
  }: IGameFavouritesRequest): Promise<IFavouriteResponse> => {
    const gameId = game.id;
    if (!gameStore.favoriteIds.includes(gameId)) {
      const params = {
        channel,
        languageCode,
        currency,
        gameId,
        environment: appConfig.public.environment || 'development',
      };
      const requestUrl: string = base.requestUrl({
        path: `api/v1/Gaming/Game/${gameId}/Favourite`,
        queryParams: params,
      });
      return await retryAuthenticatedRequest<IFavouriteResponse>(requestUrl, {
        method: 'post',
        body: {
          channel: channel,
          languageCode: languageCode,
          currency: currency,
        },
        query: params,
        headers: {
          'X-Vertical': vertical,
          'X-Environment': appConfig.public.environment || 'development',
        },
        onResponse() {
          gameStore.setFavorite(game, true);
        },
      });
    }
    return;
  };

  const deleteGameFavourites = async ({
    channel,
    languageCode,
    vertical,
    game,
  }: IGameFavouritesRequest): Promise<IFavouriteResponse> => {
    const gameId = game.id;
    const params = {
      channel,
      languageCode,
      gameId,
      environment: appConfig.public.environment || 'development',
    };
    const requestUrl: string = base.requestUrl({
      path: `api/v1/Gaming/Game/${gameId}/Favourite`,
    });
    return await retryAuthenticatedRequest<IFavouriteResponse>(requestUrl, {
      method: 'delete',
      body: {
        channel: channel,
        languageCode: languageCode,
      },
      query: params,
      headers: {
        'X-Vertical': vertical,
        'X-Environment': appConfig.public.environment || 'development',
      },
      onResponse() {
        gameStore.setFavorite(game, false);
      },
    });
  };

  const rateGame = async ({ gameId, rating }: IGameRatingRequest) => {
    const params = {
      gameId,
      rating,
      environment: appConfig.public.environment || 'development',
    };
    const requestUrl: string = base.requestUrl({
      path: `api/v1/Gaming/Game/${gameId}/rating/${rating}`,
    });

    return await retryAuthenticatedRequest<IFavouriteResponse>(requestUrl, {
      method: 'post',
      body: {},
      query: params,
      headers: {
        'X-Environment': appConfig.public.environment || 'development',
      },
    });
  };

  const getRating = async ({ gameId }: IGetGameRating) => {
    const requestUrl: string = base.requestUrl({
      path: `api/v1/Gaming/${gameId}/rating`,
    });

    return await retryAuthenticatedRequest(requestUrl, {
      headers: {
        'X-Environment': appConfig.public.environment || 'development',
      },
    });
  };

  const getLikeCount = async ({ gameId }: IGetGameRating) => {
    const requestUrl: string = base.requestUrl({
      path: `api/v1/Gaming/${gameId}/likeCount`,
    });
    return await retryAuthenticatedRequest(requestUrl, {
      headers: {
        'X-Environment': appConfig.public.environment || 'development',
      },
    });
  };

  const setGames = (data: Array<IGameCategories>) => {
    if (!!data) {
      const games = ref<string[]>([]);

      //iterate through categories
      data?.forEach((cat) => {
        //map all game ID's per category and then concat the results
        games.value = games?.value?.concat(cat?.games?.map((game) => game?.id));
      });

      //remove duplicate IDs
      games.value = [...new Set(games?.value)];
      gameStore.addGames(games?.value);
    }
  };

  const logPlaytime = async ({ gameId, startDateTime }: IPlaytimeRequest) => {
    const requestUrl: string = base.requestUrl({
      path: `api/v1/Gaming/Game/${gameId}/playtime`,
    });

    return await retryAuthenticatedRequest(requestUrl, {
      method: 'post',
      body: { startDateTime, endDateTime: dayjs().toISOString() },
      headers: {
        'X-Environment': appConfig.public.environment || 'development',
      },
    });
  };

  const getRecentlyPlayed = async (size: number = 12) => {
    const requestUrl: string = base.requestUrl({
      path: `api/v3/Gaming/Recent`,
    });

    await retryAuthenticatedRequest<IGameCategory[]>(requestUrl, {
      method: 'get',
      query: { size },
      headers: {
        'X-Environment': appConfig.public.environment || 'development',
      },
    }).then((data) => {
      if (!!data) gameStore.hydrateRecentlyPlayed(data);
    });
  };

  return {
    fetchGamesByProvider,
    fetchCategories,
    fetchGame,
    fetchGameByAlias,
    fetchCategory,
    fetchSearchedGames,
    fetchPersonalisedGames,
    fetchGameFavourites,
    addGameFavourites,
    deleteGameFavourites,
    rateGame,
    getRating,
    getLikeCount,
    fetchBuildVersion,
    setGames,
    logPlaytime,
    getRecentlyPlayed,
  };
};
