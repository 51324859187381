import { defineStore } from 'pinia';
import type { Modals } from '~/interfaces/dto/general/modals';
import type { ISitemapRemap } from '~/interfaces/dto/sitemap/response';
import type {
  position,
  severity,
} from '~/interfaces/dto/general/toast-messages';
import type { IConfigResponse } from '~/interfaces/dto/config/response';
import {
  brandCountryDetails,
  brandCurrencies,
  defaultFeatureFlags,
  defaultRedirects,
  defaultSitemap,
} from 'assets/data/config';

interface ISiteNotice {
  heading: string;
  message: string;
  severity: severity;
  enabled: boolean;
  timeout: number;
  position?: position;
}

type Nav = 'top' | 'left' | 'footer';

interface ISiteStore {
  region: string;
  regionCode: string;
  environment: 'development' | 'staging' | 'uat' | 'production' | string;
  redirects: { fromLink: string; toLink: string }[];
  redirectsHydrated: boolean;
  sitemapHost: string;
  cmsHost: string;
  sitemapMap: Map<string, Partial<ISitemapRemap>>;
  config: IConfigResponse;
  loading: boolean;
  fullscreenLoading: boolean;
  displayFilters: boolean;
  displayMarketingBanner: boolean;
  displayNotifications: boolean;
  displaySearch: boolean;
  displayLiveChat: boolean;
  siteNotice: ISiteNotice;
  unreadNotificationsCount: number;
  modalActive: boolean;
  hamburgerMenuActive: boolean;
  currentModal: Modals | undefined | string | null;
  featureFlags: Map<string, boolean>;
  logo: string;
  webview: boolean;
}

export const useSiteStore = defineStore('site', {
  state: (): ISiteStore => {
    return {
      region: 'ZA',
      regionCode: 'JZA',
      environment: 'production',
      redirects: [],
      redirectsHydrated: false,
      sitemapHost: 'www.jackpotcity.co.za',
      cmsHost: 'cms1.jpc.africa',
      sitemapMap: new Map(Object.entries(defaultSitemap)),
      config: null,
      loading: false,
      fullscreenLoading: false,
      displayFilters: false,
      displayMarketingBanner: true,
      displayNotifications: false,
      displaySearch: false,
      displayLiveChat: false,
      siteNotice: {
        heading: '',
        message: '',
        severity: 'error',
        enabled: false,
        timeout: 3000,
        position: 'bl',
      },
      unreadNotificationsCount: 0,
      modalActive: false,
      hamburgerMenuActive: false,
      currentModal: null,
      featureFlags: new Map(Object.entries(defaultFeatureFlags)),
      logo: 'roa',
      webview: false,
    };
  },
  actions: {
    toggleFeature({ feature }) {
      this[feature] = !this[feature];
      if (feature === 'displayNotifications') {
        localStorage.setItem('notificationsPanel', 'isOpen');
      }
    },
    explicitToggleFeature({
      feature,
      toggle,
    }: {
      feature: string;
      toggle: boolean;
    }) {
      this[feature] = toggle;
    },
    setLoading(isLoading: boolean) {
      this.loading = isLoading;
    },
    setSiteMap(sitemap: Partial<ISitemapRemap>[]) {
      this.sitemapMap.clear();
      sitemap?.forEach((sm) => this.sitemapMap.set(sm.Path, sm));
    },
    addToSiteMap(sitemapEntry: Partial<ISitemapRemap>) {
      this.sitemapMap.set(sitemapEntry.Path, sitemapEntry);
    },
    setSitemapHost(host: string) {
      this.sitemapHost = host;
    },
    setCmsHost(host: string) {
      this.cmsHost = host;
    },
    setLogo() {
      const url = useRequestURL();
      const hostName: string = url.hostname;
      const hostCheck = hostName.includes('casino');
      if (hostCheck) this.logo = 'roa';
      else this.logo = 'za';
    },
    setRedirects(redirects: { fromLink: string; toLink: string }[]) {
      this.redirects = [...redirects, ...defaultRedirects];
      this.redirectsHydrated = true;
    },
    setConfig(config) {
      this.config = config;
      const featureFlags = config?.featureFlags;
      this.setLogo();
      if (!!featureFlags && featureFlags?.length > 0) {
        featureFlags.forEach((ff) => {
          const features = ff.features || ff.Features;
          const ffKey = ff.key || ff.Key;
          //Get FeatureFlag
          features.forEach((featureFlag) => {
            const featureKey = featureFlag.key;
            const enabled = featureFlag.enabled;
            //add entry into lookup table for each feature flag
            const key = `${ffKey}.${featureKey}`
              .toLowerCase()
              .trim()
              .replace('\t', '');
            this.setFeatureFlag(key, enabled);
          });
        });
      }
    },
    setEnvironment(
      environment: 'development' | 'staging' | 'uat' | 'production' | string,
    ) {
      this.environment = environment;
    },
    setRegion(region: string) {
      const config = useRuntimeConfig();
      const { environment } = config.public;

      this.environment = environment;

      const r = region.toUpperCase();

      this.region = r;
      this.regionCode = `J${r}`;
    },
    toggleSiteNotice({ heading, message, severity, enabled, timeout }) {
      this.siteNotice = {
        ...this.siteNotice,
        heading,
        message,
        severity,
        enabled,
        timeout,
      };
    },
    setUnreadNotificationsCount(element: number) {
      this.unreadNotificationsCount = element;
    },
    activateModal(activeModal: Modals | string) {
      this.currentModal = activeModal;
      this.modalActive = true;
    },
    toggleHamburgerMenu() {
      this.hamburgerMenuActive = !this.hamburgerMenuActive;
    },
    setHamburgerMenu(active: boolean) {
      this.hamburgerMenuActive = active;
    },
    deactivateModal() {
      this.currentModal = null;
      this.modalActive = false;
    },
    setFeatureFlag(key: string, val: boolean) {
      this.featureFlags.set(key, val);
    },
    setWebview(isWebview: boolean) {
      this.webview = isWebview;
    },
  },
  getters: {
    featureDisplay(state) {
      return ({ feature }) => state[feature];
    },
    isLoading(state) {
      return state.loading;
    },
    isFullscreenLoading(state) {
      return state.fullscreenLoading;
    },
    getNav(state) {
      const sitemap = [...state.sitemapMap.values()];
      return ({ nav }: { nav: Nav }) =>
        sitemap?.filter((item) => {
          return item?.Navigation[nav];
        });
    },
    getRedirects(state) {
      return state.redirects;
    },
    getRedirectsHydrated(state) {
      return state.redirectsHydrated;
    },
    getSitemapMap(state) {
      return state.sitemapMap;
    },
    getSitemapHost(state) {
      return state.sitemapHost;
    },
    getCmsHost(state) {
      return state.cmsHost;
    },
    getConfig(state) {
      return state.config;
    },
    getDialingCode(state) {
      return brandCountryDetails[state.regionCode].countryDialingCode;
    },
    getCurrencyCode(state) {
      return brandCurrencies[state.regionCode].currencyCode;
    },
    getRegion(state) {
      return state.region;
    },
    getRegionCode(state) {
      return state.regionCode;
    },
    getEnvironment(state) {
      return state.environment;
    },
    getSiteNotice(state) {
      return state.siteNotice;
    },
    getUnreadNotificationsCount(state) {
      return state.unreadNotificationsCount;
    },
    getModalActive(state) {
      return state.modalActive;
    },
    getHamburgerMenuActive(state) {
      return state.hamburgerMenuActive;
    },
    getCurrenModal(state) {
      return state.currentModal;
    },
    getFeatureFlags(state) {
      return state.featureFlags;
    },
    getLogo(state) {
      return state.logo;
    },
    isWebview(state) {
      return state.webview;
    },
  },
});
