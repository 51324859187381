const authKeys = {
  accessToken: 'auth:access_token',
  refreshToken: 'auth:refresh_token',
  userId: 'auth:userId',
  user: 'auth:user',
  passwordResetNumber: 'auth:password-reset-number',
  hideBalances: 'auth:show-balances',
  sessionTimer: 'auth:session-timer',
  recentlyPlayed: (userId: string) => `recently-played:${userId}`,
};
const promotionKeys = {
  genericWheel: 'generic-wheel:spin-count',
};
const marketingKeys = {
  bannerTag: 'BTAGCOOKIE',
  referrerBannerTag: 'REFERRERBTAGCOOKIE',
  masterToken: 'MT',
  sessionToken: 'ST',
  marketingSession: 'MkSession',
  originalQueryString: 'OriginalQueryString',
  optinSession: 'marketing:opt-in-session',
  signUpCode: 'marketing:sign-up-code',
};

const siteConfigKeys = {
  // !NB: without prefix for external parties
  locale: 'locale',
  deviceInfo: 'site:device-info',
  buildTimestamp: 'site:build-time-stamp',
  // !NB: without prefix for external parties
  environment: 'environment',
  acceptCookies: 'site:cookies-accepted',
  persistPanel: (panelName: string) => `site:panel-${panelName}`,
  colorMode: 'nuxt-color-mode',
};

const developerConfigKeys = {
  debugRegion: 'debug:region',
  debugEnvironment: 'debug:environment',
};

const navigationKeys = {
  lastVertical: 'navigation:last-vertical',
  lastCategory: 'navigation:last-category',
  lastRoute: 'navigation:last-route',
};
export const globalKeys = {
  authKeys,
  promotionKeys,
  siteConfigKeys,
  marketingKeys,
  developerConfigKeys,
  navigationKeys,
};
