import { ZA, GH, ZM, TZ, MW } from 'country-flag-icons/string/3x2';
import type { ISitemapRemap } from '~/interfaces/dto/sitemap/response';

export const regions = [
  { name: 'South Africa', code: 'ZA', flag: import.meta.dev ? ZA : null },
  { name: 'Ghana', code: 'GH', flag: import.meta.dev ? GH : null },
  { name: 'Zambia', code: 'ZM', flag: import.meta.dev ? ZM : null },
  { name: 'Tanzania', code: 'TZ', flag: import.meta.dev ? TZ : null },
  { name: 'Malawi', code: 'MW', flag: import.meta.dev ? MW : null },
];

export const brandIds = {
  JTZ: 'E430B4FB-55DC-42CC-AA65-1057CE49C7E6',
  JAO: 'FEE7672B-53BA-45B7-823F-1E61A9B9509B',
  JKE: 'B33B59F1-E3D4-449A-9696-2201A185A858',
  JET: '5A533B6D-80CC-42D0-802E-804E3662540A',
  JCM: 'F86AFCDE-0FCB-4197-9207-87EFD66409A1',
  JZM: 'D17024C3-F417-4D1C-B142-9E5F9E83B49B',
  JMZ: 'CFF8B795-9939-432F-A73D-B2393F8E658D',
  JZA: 'D76A0E62-B728-4A28-8134-C57A1A003199',
  JUG: '75EA7131-252E-44C3-B369-CC6DA7192FAE',
  JNG: 'DBAE2A59-1E73-4DA2-B169-CDA0239BCF78',
  JMW: 'CA968023-3E8E-43BC-B3AA-DB01C469A4C6',
  JGH: 'BE6CDE0B-73E4-448A-A706-F9C20CF3F669',
};

export const defaultLocales = {
  JTZ: 'sw-TZ',
  JAO: 'en-US',
  JKE: 'sw-KE',
  JET: 'en-US',
  JCM: 'en-US',
  JZM: 'en-US',
  JMZ: 'pt-MZ',
  JZA: 'en-US',
  JUG: 'en-US',
  JNG: 'en-US',
  JMW: 'en-US',
  JGH: 'en-US',
};

export const subdomainLocales = {
  en: 'en-US',
  sw: 'sw-TZ',
  pt: 'pt-MZ',
};

export const brandDomainSuffix = {
  JTZ: 'co.tz',
  JAO: 'co.ag',
  JKE: 'co.ke',
  JET: 'co.et',
  JCM: 'cm',
  JZM: 'co.zm',
  JMZ: 'com.mz',
  JZA: 'co.za',
  JUG: 'ug',
  JNG: 'com.ng',
  JMW: 'mw',
  JGH: 'com.gh',
};

export const brandCurrencies = {
  JZA: {
    currencyCode: 'ZAR',
    currencyName: 'South African Rand',
    currencyShortName: 'South African Rand',
  },
  JMW: {
    currencyCode: 'MWK',
    currencyName: 'Malawian Kwacha',
    currencyShortName: 'Malawian Kwacha',
  },
  JTZ: {
    currencyCode: 'TZS',
    currencyName: 'Tanzanian Shilling',
    currencyShortName: 'Tanzanian Shilling',
  },
  JGH: {
    currencyCode: 'GHS',
    currencyName: 'Ghanaian New Cedi',
    currencyShortName: 'Ghanaian New Cedi',
  },
  JZM: {
    currencyCode: 'ZMW',
    currencyName: 'Zambian Kwacha',
    currencyShortName: 'Zambian Kwacha',
  },
};

export const brandCountryDetails = {
  JZA: {
    countryDialingCode: '+27',
    countryIsoThree: 'ZAF',
    countryIsoTwo: 'ZA',
    countryName: 'South Africa',
  },
  JMW: {
    countryDialingCode: '+265',
    countryIsoThree: 'MWI',
    countryIsoTwo: 'MW',
    countryName: 'Malawi',
  },
  JTZ: {
    countryDialingCode: '+255',
    countryIsoThree: 'TZA',
    countryIsoTwo: 'TZ',
    countryName: 'Tanzania',
  },
  JGH: {
    countryDialingCode: '+233',
    countryIsoThree: 'GHA',
    countryIsoTwo: 'GH',
    countryName: 'Ghana',
  },
  JZM: {
    countryDialingCode: '+233',
    countryIsoThree: 'GHA',
    countryIsoTwo: 'GH',
    countryName: 'Ghana',
  },
};

export const liveChatIds = {
  JTZ: '4e5e503d-2408-4516-ab2f-3e2528ff33c4',
  JMW: '113c467a-65ec-4e9d-b03b-9eb8a8eb3fc1',
  JZA: '0ac33e78-8b1b-4c3c-9209-cc5bbeedd930',
};

export const socialLinks = {
  JZA: {
    facebook: 'https://www.facebook.com/people/jackpotcitsa/61554440885293',
    instagram: 'https://www.instagram.com/jackpotcitysa',
    x: 'https://x.com/JackPotCitySA',
  },
  JTZ: {
    facebook: 'https://www.facebook.com/JackpotCityCasinoTanzania',
    instagram: 'https://www.instagram.com/jackpotcitycasinotz/',
    x: 'https://x.com/JPCCasinoTZ',
  },
  JMW: {
    facebook: 'https://www.facebook.com/JackpotCityCasinoMalawi',
    instagram: 'https://www.instagram.com/jackpotcitycasinomw',
    x: 'https://x.com/JPCCasinoMW',
  },
};

export const captcha = {
  JZA: '6LfmZlwqAAAAADj8PrXTMfCnEuvlzFw_YbLjRePM',
  JMW: '6LdeSFwqAAAAAHqAGT1c0qJXO7e9HgUw7vw4pFRQ',
  JTZ: '6LfNH1wqAAAAAIgdhSVIboLhSPRr5onGjyclhUP_',
};

export const defaultFeatureFlags = {
  // website
  'website.enablefreeplay': false,
  'website.enablerecaptcha': true,
  'website.toastmessages': true,
  'website.cookiebar': true,
  'website.enableappdownload': true,
  'website.truncatecurrency': true,
  'website.enablesecondarynavigation': false,
  'website.enablesocialicons': true,
  'website.notificationpanel': true,
  'website.livechat': true,
  // responsible gaming
  'responsiblegaming.wagerlimit': true,
  'responsiblegaming.sessionlimit': true,
  'responsiblegaming.coolingoffperiod': true,
  'responsiblegaming.selfexclusion': true,
  // my account
  'myaccount.cashbalance': true,
  'myaccount.bonusbalance': true,
  'myaccount.totalbalance': true,
  'myaccount.enablewithdrawalrefund': true,
};

export const defaultSitemap: Record<string, Partial<ISitemapRemap>> = {
  '/': {
    nuxtRouteParams: { name: '', params: {}, path: '/' },
    nuxtSeoParams: {
      title: 'Jackpot City | Online Betting',
      description: 'Go all in at Jackpot City. Spin your way to the Jackpot!',
      ogTitle: 'Jackpot City | Online Betting',
      twitterTitle: 'Jackpot City | Online Betting',
      twitterDescription:
        'Go all in at Jackpot City. Spin your way to the Jackpot!',
      ogDescription: 'Go all in at Jackpot City. Spin your way to the Jackpot!',
      twitterCard: 'summary',
      keywords: null,
    },
    Path: '/',
    Navigation: { top: true, footer: false, left: false },
    PageName: 'Home',
    Alias: 'home',
    DocumentPageDescription:
      'Go all in at Jackpot City. Spin your way to the Jackpot!',
    DocumentPageKeyWords: null,
    DocumentPageTitle: 'Jackpot City | Online Betting',
    Mapping: 'Home',
    MobileApp: { enabled: true, component: 'HomeComponent', content: null },
    Children: [],
  },
  '/promotions': {
    nuxtRouteParams: {
      name: '',
      params: {},
      path: '/promotions',
    },
    nuxtSeoParams: {
      title: 'Jackpot City | Bonuses & Promotions',
      description:
        'Relish in Jackpot City’s lucrative bonuses & promotions that amplify your game play. Strap in for daily, weekly and monthly promos.',
      ogTitle: 'Jackpot City | Bonuses & Promotions',
      twitterTitle: 'Jackpot City | Bonuses & Promotions',
      twitterDescription:
        'Relish in Jackpot City’s lucrative bonuses & promotions that amplify your game play. Strap in for daily, weekly and monthly promos.',
      ogDescription:
        'Relish in Jackpot City’s lucrative bonuses & promotions that amplify your game play. Strap in for daily, weekly and monthly promos.',
      twitterCard: 'summary',
      keywords: null,
    },
    Path: '/promotions',
    Navigation: {
      top: true,
      footer: false,
      left: false,
    },
    PageName: 'Promotions',
    Alias: 'Promotions',
    DocumentPageDescription:
      'Relish in Jackpot City’s lucrative bonuses & promotions that amplify your game play. Strap in for daily, weekly and monthly promos.',
    DocumentPageKeyWords: null,
    DocumentPageTitle: 'Jackpot City | Bonuses & Promotions',
    Mapping: '/promotions/jackpotcity/jza',
    MobileApp: {
      enabled: true,
      component: 'PromotionComponent',
      content: null,
    },
    Children: [],
  },
};

export const environmentVariables = {
  development: {
    kentico: 'https://dev.jackpotcity.co.za/api/kenticodv',
    authApi: 'https://dev-app.jpc.africa/auth',
    balance: 'https://dev-app.jpc.africa/balance',
    player: 'https://dev-app.jpc.africa/player',
    rewards: 'https://dev-loyalty-external.betwayafrica.com',
    casinoBonus: 'https://dev-casinobonusing.jpc.africa',
    config: 'https://dev.jackpotcity.co.za/api/configdv',
    promotions: 'https://promoapi-dev.jpc.africa',
    environment: 'development',
    bankingEnvironment: 'dev.jackpotcity',
  },
  qa: {
    kentico: 'https://qa.jackpotcity.co.za/api/kenticostg',
    authApi: 'https://uat-app.jpc.africa/auth',
    balance: 'https://uat-app.jpc.africa/balance',
    player: 'https://uat-app.jpc.africa/player',
    rewards: 'https://uat-app.easterndawn.io/loyaltyexternal',
    casinoBonus: 'https://uat-casinobonusing.jpc.africa',
    config: 'https://qa.jackpotcity.co.za/api/configstg',
    promotions: 'https://promoapi-dev.jpc.africa',
    environment: 'staging',
    bankingEnvironment: 'qa.jackpotcity',
  },
  uat: {
    kentico: 'https://cms1.jpc.africa',
    authApi: 'https://app.jpc.africa/auth',
    balance: 'https://app.jpc.africa/balance',
    player: 'https://app.jpc.africa/player',
    rewards: 'https://loyalty-external.jpc.africa',
    casinoBonus: 'https://casinobonusing.jpc.africa',
    config: 'https://uat.jackpotcity.co.za/api/configuat',
    promotions: 'https://promoapi.jpc.africa',
    environment: 'uat',
    bankingEnvironment: 'www.jackpotcity',
  },
  production: {
    kentico: 'https://cms1.jpc.africa',
    authApi: 'https://app.jpc.africa/auth',
    balance: 'https://app.jpc.africa/balance',
    player: 'https://app.jpc.africa/player',
    rewards: 'https://loyalty-external.jpc.africa',
    casinoBonus: 'https://casinobonusing.jpc.africa',
    config: 'https://config.jpc.africa',
    promotions: 'https://promoapi.jpc.africa',
    environment: 'production',
    bankingEnvironment: 'www.jackpotcity',
  },
};

export const passwordRegex = {
  JZA: { strong: '^(.){8,20}$', medium: '^(.){1,20}$' },
  JMW: { strong: '^(.){8,20}$', medium: '^(.){1,20}$' },
  JTZ: { strong: '^(.){4,20}$', medium: '^(.){1,20}$' },
  JZM: { strong: '^(.){8,20}$', medium: '^(.){1,20}$' },
  JGH: { strong: '^(.){8,20}$', medium: '^(.){1,20}$' },
};

export const regionPasswordSuggestions = {
  JZA: [{ min: 8 }, { max: 20 }, { vulnerable: true }],
  JMW: [{ min: 8 }, { max: 20 }, { vulnerable: true }],
  JTZ: [{ min: 4 }, { max: 20 }, { vulnerable: true }],
  JZM: [{ min: 8 }, { max: 20 }, { vulnerable: true }],
  JGH: [{ min: 8 }, { max: 20 }, { vulnerable: true }],
};

export const defaultRedirects: { fromLink: string; toLink: string }[] = [
  {
    fromLink: '/login',
    toLink: '/?login=1',
  },
  {
    fromLink: '/register',
    toLink: '/?register=1',
  },
  {
    fromLink: '/home',
    toLink: '/',
  },
  {
    fromLink: '/winners',
    toLink: '/winners-circle',
  },
];

export const changeWagerLimitCooldown = 7;
