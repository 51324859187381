import { useAuthStore } from '~/stores/auth.store';
import { useAuthService } from '~/composables/services/useAuthService';
import type { NitroFetchOptions } from 'nitropack';
import { brandIds } from '~/assets/data/config';

export default function <T>(url: string, options?: NitroFetchOptions<any>) {
  const authStore = useAuthStore();
  const authService = useAuthService();
  const siteStore = useSiteStore();
  if (!!authStore.access_token)
    return $fetch<T>(url, {
      ...options,
      retry: 1,
      retryStatusCodes: [401],
      onRequest({ options }) {
        options.headers.set('X-Brand-Id', brandIds[siteStore.getRegionCode]);
        options.headers.set(
          'Authorization',
          `Bearer ${authStore.access_token}`,
        );
      },
      async onResponseError(context) {
        if (context.response.status === 401) {
          await authService.refreshToken();
        }
      },
    });
}
