import { BaseService } from '~/services/base.service';
import type { IContentResponse } from '~/interfaces/dto/content/response';
import type {
  IPromotion,
  IPromotionsResponse,
  IPromotionsResponseMap,
} from '~/interfaces/dto/promotions/response';
import type { FetchContext, FetchResponse } from 'ofetch';
import { remapPromotions } from '~/utilities/promtions-mapper';
import { parsePromotionContent } from '~/utilities/dom-parser';
import { useSiteStore } from '~/stores/site.store';
import type { PostContentType } from '~/interfaces/content/shared';

export const useContentService = () => {
  const appConfig = useRuntimeConfig();
  const base = new BaseService(appConfig.public.kentico);

  const siteStore = useSiteStore();
  const localeStore = useLocaleStore();

  const fetchSpecialContent = async (
    contentType: PostContentType,
    host?: string,
  ) => {
    const route = useRoute();
    const siteStore = useSiteStore();
    const url = [
      contentType,
      siteStore.getRegionCode,
      ...Object.values(route.params),
    ].join('/');

    return fetchContent(`/${url}`, host, false);
  };

  const fetchContent = async (
    path: string,
    host?: string,
    replacePaths: boolean = true,
  ): Promise<IContentResponse> => {

    const params = {
      host: host || siteStore.getSitemapHost,
      // this needs to be updated
      route: `${path.startsWith('/') ? path : '/' + path}`,
      lang: localeStore.getLocale.split('-')[0],
    };

    const { getCache, setCache } = await useCache<{ content: string }>(
      `content:${params.route}-${params.host}-${params.lang}`,
    );

    if (!!getCache) return getCache;

    const requestUrl: string = base.requestUrl({
      path: 'gmapi/Content/cmsget',
      queryParams: params,
    });

    return await $fetch<{ content: string }>(requestUrl, {
      async onResponse(context) {
        if (
          (params?.host?.includes('.dv') || params?.host?.includes('.stg')) &&
          replacePaths
        ) {
          const regex =
            /https:\/\/[A-Za-z0-9]+\.[A-Za-z]+\.[A-Za-z]+\.[A-Za-z]+/g;
          const moddedData = {
            content: context.response._data.content?.replaceAll(
              regex,
              appConfig.public.kentico,
            ),
          };
          context.response._data = moddedData;
          await setCache(moddedData);
        } else {
          await setCache(context.response._data);
        }
      },
      onResponseError(context) {
        console.error(context);
      },
    });
  };

  const fetchContentDirect = async (path: string): Promise<string> => {
    const requestUrl: string = base.requestUrl({
      path: path.startsWith('/') ? path.replace('/', '') : path,
      queryParams: {
        culture: 'en-US',
      },
    });
    return await $fetch(requestUrl);
  };

  const fetchBigCityLifeCategory = async (
    requestUrl: string,
  ): Promise<string> => {
    return await $fetch(requestUrl);
  };

  const fetchPromotions = async (): Promise<IPromotionsResponseMap[]> => {
    const requestUrl: string = base.requestUrl({
      path: 'gmapi/site/promotionsv2',
      queryParams: {
        host: siteStore.getCmsHost,
        culture: 'en-US',
        brand: 'jackpotcity',
        brandCode: siteStore.getRegionCode,
      },
    });
    return await $fetch(requestUrl, {
      server: false,
      async onResponse(
        context: FetchContext & {
          response: FetchResponse<IPromotionsResponse>;
        },
      ): Promise<void> {
        const contentMap =
          context.response._data?.promotionBlocks?.map(
            async (promotion: IPromotion) => {
              try {
                const content: string = await fetchContentDirect(promotion.P);
                const mappedPromotion = remapPromotions([promotion]);
                return {
                  content: parsePromotionContent(content),
                  ...mappedPromotion[0],
                };
              } catch (e) {
                console.error(e);
              }
            },
          ) || [];

        context.response._data =
          contentMap?.length > 0 ? await Promise.all(contentMap) : [];
      },
    });
  };

  return {
    fetchSpecialContent,
    fetchContent,
    fetchPromotions,
    fetchContentDirect,
    fetchBigCityLifeCategory,
  };
};
