import { useAuthService } from './services/useAuthService';
import { useSiteStore } from '~/stores/site.store';
import { useRgStore } from '~/stores/responsibleGaming.store';
import type {
  IBrandGetLimitsType,
  IBrandLimits,
  IUserGetLimitsType,
  IUserLimits,
  IWagerProgress,
  IWagerProgressType,
  ISelfExclusionReasons,
  IUserLimitsRestrictionType,
} from '~/interfaces/dto/player/response';
import { globalKeys } from 'assets/data/global-keys';
export const sessionTimeout = () => useState('sessionTimeout', () => null);
export const useCheckRestrictionsService = async (): Promise<void> => {
  const site = useSiteStore();
  await useGetUserLocks();
  useFetchBrandLimitsLocks().finally(() => {
    if (site.getFeatureFlags.get('responsiblegaming.selfexclusion')) {
      useGetExclusions();
    }
  });
  useGetUserLimits().finally(() => {
    if (site.getFeatureFlags.get('responsiblegaming.wagerlimit')) {
      useGetWagerProgress();
    }
    if (site.getFeatureFlags.get('responsiblegaming.sessionlimit')) {
      useSessionTimeout();
    }
  });
};
export const useFetchBrandLimitsLocks = async (): Promise<void> => {
  useGetBrandLimits();
  useGetBrandLocks();
};
export const useGetUserLimits = async (): Promise<void> => {
  const rg = useRgStore();
  const $authService = useAuthService();
  const userLimits: Partial<IUserGetLimitsType> =
    await $authService.getUserLimits();
  const activeUserLimits: IUserLimits[] =
    userLimits?.data?.restrictionResponses?.filter(
      (limit: IUserLimits): boolean => limit.isActive === true,
    );
  if (activeUserLimits) {
    rg.setUserLimits(activeUserLimits);
  }
};
export const useGetBrandLimits = async (): Promise<void> => {
  const rg = useRgStore();
  const $authService = useAuthService();
  const brandLimits: Partial<IBrandGetLimitsType> =
    await $authService.getBrandLimits();
  const activeBrandLimits: IBrandLimits[] =
    brandLimits?.data?.restrictionTypeResponses;
  rg.setBrandLimits(activeBrandLimits);
};
export const useGetBrandLocks = async (): Promise<void> => {
  const rg = useRgStore();
  const $authService = useAuthService();
  const brandLocks: Partial<IBrandGetLimitsType> =
    await $authService.getBrandLocks();
  const activeBrandLocks: IBrandLimits[] =
    brandLocks?.data?.restrictionTypeResponses;
  rg.setBrandLocks(activeBrandLocks);
};
export const useGetWagerProgress = async (): Promise<void> => {
  const rg = useRgStore();
  const $authService = useAuthService();
  const durations: Partial<IWagerProgress> =
    await $authService.getWagerProgress();
  const durationProgress: IWagerProgressType[] = durations?.data?.wagerLimits;
  rg.setWagerDuration(durationProgress);
};
export const useSessionTimeout = (redirect?: boolean): void => {
  const { $storageService } = useServices();
  const rg = useRgStore();
  const site = useSiteStore();
  const sessionLimit = ref<number>(rg.currentSessionLimit);
  const totalMins = ref<number>(rg.currentTimer);
  const persistTimer: number =
    Number(
      $storageService.getLocalStorage({
        key: globalKeys.authKeys.sessionTimer,
      }),
    ) || null;
  if (!!sessionLimit.value && !!totalMins.value) {
    if (rg.timerSet === false) {
      rg.setTimerSet();
      if (!persistTimer) {
        $storageService.setLocalStorage({
          key: globalKeys.authKeys.sessionTimer,
          data: rg.currentTimer,
        });
      } else {
        totalMins.value = persistTimer;
      }
      sessionTimeout().value = setInterval((): void => {
        if (totalMins.value === 0) {
          clearInterval(sessionTimeout().value);
          $storageService.removeLocalStorage({
            key: globalKeys.authKeys.sessionTimer,
          });
          rg.timerSet = false;
          site.activateModal('sessionLimitExceeded');
        } else if (totalMins.value > 0) {
          totalMins.value -= 1;
          $storageService.setLocalStorage({
            key: globalKeys.authKeys.sessionTimer,
            data: totalMins.value,
          });
        }
      }, 1000);
    }
  } else {
    $storageService.removeLocalStorage({
      key: globalKeys.authKeys.sessionTimer,
    });
    if (!!sessionTimeout().value) {
      clearInterval(sessionTimeout().value);
      sessionTimeout().value = null;
      $storageService.removeLocalStorage({
        key: globalKeys.authKeys.sessionTimer,
      });
    }
  }
};

export const wagerProgress = (name: string): number => {
  const rg = useRgStore();
  let updatedProgress: number;
  if (rg.getWagerDuration === null) {
    updatedProgress = 0;
  } else {
    rg.getWagerDuration.filter((duration: IWagerProgressType): void => {
      if (duration?.limitName.startsWith(name)) {
        updatedProgress = duration?.progressTowardThreshold;
      }
    });
  }
  return updatedProgress;
};
export const useGetExclusions = async (): Promise<void> => {
  const rg = useRgStore();
  const $authService = useAuthService();
  const exclusion: Partial<ISelfExclusionReasons> =
    await $authService.getExclusions();
  rg.setExclusions(exclusion?.data);
};
export const useGetUserLocks = async (): Promise<void> => {
  const rg = useRgStore();
  const $authService = useAuthService();
  const userLocks: Partial<IUserGetLimitsType> =
    await $authService.getUserLocks();
  const activeUserLocks: IUserLimits[] =
    userLocks?.data?.restrictionResponses?.filter(
      (lock: IUserLimits): boolean => lock.isActive === true,
    );
  if (activeUserLocks) {
    rg.setUserLocks(activeUserLocks);
  }
};
export const getAddOrUpdate = (id: string, type: 'lock' | 'limit'): {} => {
  const rg = useRgStore();
  let result: {};
  if (type === 'limit') {
    if (rg.userLimits === null) {
      return null;
    } else {
      result = rg.userLimits.find(
        (limit: Partial<IUserLimits>): boolean =>
          limit?.restrictionType?.restrictionTypeId === id,
      );
    }
  } else {
    if (rg.userLocks === null) {
      return null;
    } else {
      result = rg.userLocks.filter(
        (lock: Partial<IUserLimits>): boolean =>
          lock?.restrictionType?.restrictionTypeId === id,
      );
    }
  }
  return result;
};
export const useGetRestrictionObject = (
  mapping: string,
  type: 'lock' | 'limit',
  source: 'user' | 'brand',
): Partial<IUserLimitsRestrictionType> => {
  const rg = useRgStore();
  let restriction;
  let state;
  if (source === 'brand') {
    state = type === 'lock' ? rg.brandLocks : rg.brandLimits;
    if (state !== null) {
      state.forEach((limit: IBrandLimits) => {
        if (limit?.mappingCode === mapping) {
          restriction = limit;
        }
      });
    }
  } else {
    state = source === 'user' ? rg.userLimits : rg.userLocks;
    if (state !== null) {
      state.forEach((limit: IUserLimits) => {
        if (limit?.restrictionType?.mappingCode === mapping) {
          restriction = limit;
        }
      });
    }
  }
  return restriction;
};
